export const TiaraTrackPage = (page, viewName) => {
  TiaraTracker.getInstance().setPage(page).trackPage(viewName).track();
};

export const TiaraTrackSearch = (term, type) => {
  const search = {
    search_term: term,
    search_type: type,
  };

  TiaraTracker.getInstance().trackEvent(type).actionKind('Search').search(search).track();
};
